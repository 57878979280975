<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品销售</el-breadcrumb-item>
      <el-breadcrumb-item>添加商品销售</el-breadcrumb-item>
    </el-breadcrumb>
    <!--  卡片视图区  -->
    <el-card class="box-card">
      <el-form  :model="addgoodssale" :rules="addgoodssaleRules"
                ref="addgoodssaleRef" label-width="110px">
        <!--搜索与添加区域-->
        <el-row :gutter="30">
          <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
<!--            <el-button type="primary" icon="el-icon-search" @click="addGoodsListviable">添加商品</el-button>-->
            <el-button @click="addgoodssalereturn" type="primary" >选择销售订单</el-button>
          </el-col>
        </el-row>
        <el-form-item label-width="0px">
          <!--   用户列表区域   -->
          <el-table :data="addgoodssale.goodslist"  border stripe>
            <el-table-column type="index" ></el-table-column>
            <el-table-column label="商品编码" prop="ga_code" min-width="70px" show-overflow-tooltip></el-table-column>
            <el-table-column label="商品名称" prop="g_name" min-width="80px" show-overflow-tooltip></el-table-column>
            <el-table-column label="商品规格" prop="ga_name" min-width="220px" >
              <template slot-scope="scope">
                <el-tooltip effect="dark" :content="scope.row.ga_name" placement="top" :enterable="true">
                  <div class="ga_name" >{{scope.row.ga_name}}</div>
                </el-tooltip>
                <div v-if="scope.row.ga_start_date">{{scope.row.ga_start_date}}{{scope.row.ga_end_date}}</div>

              </template>
            </el-table-column>
<!--            <el-table-column label="开始时间~结束时间" width="250px">-->

<!--            </el-table-column>-->
            <el-table-column label="单位" prop="gu_name" min-width="50px" show-overflow-tooltip></el-table-column>
<!--            <el-table-column label="销售价" prop="ga_market_price" show-overflow-tooltip min-width="80px"></el-table-column>-->
<!--            <el-table-column label="会员价" min-width="80px">-->
<!--              <template slot-scope="scope">-->
<!--                <div v-if="addgoodssale.addclidValue!=''">-->
<!--&lt;!&ndash;                  {{ (scope.row.ga_cost_price*2)}}&ndash;&gt;-->
<!--&lt;!&ndash;                  {{scope.row.ga_market_price}}&ndash;&gt;-->
<!--                  {{scope.row.ga_me_price}}-->
<!--                </div>-->
<!--                <div v-else>{{scope.row.ga_market_price}}</div>-->
<!--              </template>-->
<!--            </el-table-column>-->
            <el-table-column label="最终销售价" min-width="130px">
              <template slot-scope="scope">
                <el-input v-model="scope.row.si_price" @blur="isprice(scope.row)" @input="totalPrice"
                          @keyup.native="scope.row.si_price = money(scope.row.si_price)"></el-input>
              </template>
            </el-table-column>

            <el-table-column label="销售数量" prop="si_number" min-width="70px"></el-table-column>
            <el-table-column label="数量" width="90px">
              <template slot-scope="scope">
                <el-input v-model="scope.row.sri_number" @blur="totalPrice" @input="isnumberBlur(scope.row)"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="销售总价" min-width="90px">
              <template slot-scope="scope">
                <span >{{scope.row.si_total_price}}</span>
              </template>
            </el-table-column>
            <!--    保质期    -->
<!--            <el-table-column label="操作" width="100px">-->
<!--                        <template slot-scope="scope">-->
<!--              添加规格-->
<!--                          <el-button type="primary" size="mini"-->
<!--                                     @click="salegoods(scope.row.ga_id)">销售</el-button>-->
<!--                        </template>-->
<!--            </el-table-column>-->
<!--              <el-table-column label="操作">-->
<!--                <template slot-scope="scope">-->
<!--                  <el-button type="danger" size="mini" @click="delGoodsList(scope.row.ga_id)">删除</el-button>-->
<!--                </template>-->
<!--              </el-table-column>-->
          </el-table>
        </el-form-item>

        <el-form-item label="退货员：" prop="sr_sid">
          <!--          <el-input v-model="addgoodssale.s_sale"  class="p_remark"></el-input>-->
          <el-select v-model="addgoodssale.sr_sid" placeholder="请选择退货员"
                     clearable class="p_purchase_id">
            <el-option
                v-for="item in memberList"
                :key="item.m_id"
                :label="item.m_name"
                :value="item.m_id">
            </el-option>
          </el-select>




        </el-form-item>
        <el-form-item label="退货日期：" prop="sr_date">
          <!--          <el-input v-model="addgoodssale.s_date"  class="p_remark"></el-input>-->
          <el-date-picker v-model="addgoodssale.sr_date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" class="p_remark">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="退货其他金额：" prop="s_other_price">
          <el-input v-model="addgoodssale.sr_other_price"  class="p_remark"
                    @keyup.native="addgoodssale.sr_other_pric = money(addgoodssale.sr_other_pric)"></el-input>
        </el-form-item>
        <el-form-item label="销售备注：" prop="s_remark">
<!--          <el-input v-model="addgoodssale.s_remark"  class="p_remark"></el-input>-->
          <textarea v-model="addgoodssale.sr_remark" name="" id="" cols="30" rows="10"></textarea>
        </el-form-item>

        <!--        提交-->
        <div style="float: right; padding-bottom:20px; ">
          <!--          <el-button @click="addgo">取消</el-button>-->

          <el-button type="primary" @click="salegoods" :loading="addloading">确定</el-button>
        </div>
      </el-form>
    </el-card>
    <el-dialog title="选择销售订单" :visible.sync="addGoodssaleDialogVisible"
               width="70%" >
      <el-button @click="setCurrent">取 消 选 中</el-button>
      <el-table :data="GoodssaleList" ref="singleTable" stripe highlight-current-row   @current-change="handlePCurrentChange">

        <!--        <el-table-column type="selection" width="55"></el-table-column>-->
        <el-table-column type="index" width="50px"></el-table-column>
        <el-table-column label="订单编号" prop="s_code" width="190px" ></el-table-column>
        <el-table-column label="客户名称" prop="c_name" show-overflow-tooltip></el-table-column>
        <el-table-column label="联系人" prop="cc_name" show-overflow-tooltip></el-table-column>
        <el-table-column label="收货人" prop="s_consignee" width="80px" show-overflow-tooltip></el-table-column>
        <el-table-column label="送货人" prop="s_deliverer_name" width="80px" show-overflow-tooltip></el-table-column>
        <el-table-column label="销售员" prop="s_sale_name" width="80px" show-overflow-tooltip></el-table-column>
        <el-table-column label="销售时间" prop="s_date" width="100px" show-overflow-tooltip></el-table-column>
        <el-table-column label="商品金额" prop="s_price" width="100px" show-overflow-tooltip></el-table-column>

      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addGoodssaleDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addPwtergo()">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>

export default {
  data() {
    return{
      addloading:false,
      //分页
      querInfo: {
        page: 1,
        limit: 10,
      },
      total:0,
      addGoodssaleDialogVisible:false,
      //用户列表
      memberList:[],
      //销售订单
      GoodssaleList:[],
      //提交form 表单
      addgoodssale:{
        goodslist:[],
        addclidValue:'',
        addclidID:'',
        s_id:'',
        s_code:'',
        sr_deliverer:'',
        sr_sid:this.$store.state.m_id,
        sr_consignee:'',
        sr_other_price:'',
        sr_date:this.$store.state.daydate,
        sr_remark:'',
      },
      addgoodssaleRules:{
        we_remark: [
          {required: true, message: '请销售备注', trigger: 'blur'}
        ],
      }
    }
  },
  created() {
    this.getmemberList()
    this.getpurchaseList()
  },
  methods: {
    //获取销售订单
    async getpurchaseList() {
      const {data: res} = await this.$http.get('sale/getList',
          {params:this.querInfo})
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.GoodssaleList = res.data.data
      this.total=res.data.total

    },
    //获取员工列表 member/getMainList
    async getmemberList() {
      const {data: res} = await this.$http.get('member/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.memberList = res.data
    },
    //获取销售订单详情
    async getPDli(id){
      if (id){
        const {data: res} = await this.$http.get('sale/find/'+id)
        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }
        res.data.goodslist.forEach((item)=>{
          item.pri_price=item.pi_price
          // item.sr_id=item.s_id
          // item.sri_id=item.si_id
          item.sri_number=item.si_number
          item.sri_price=item.si_price
          item.sri_total_price=item.si_total_price
          item.sri_warhouse_number=item.si_warhouse_number
        })
        this.addgoodssale.goodslist=res.data.goodslist
      }else{
        return false
      }

    },
    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getpurchaseList()
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getpurchaseList()
    },
    addgoodssalereturn(){
      this.addGoodssaleDialogVisible=true
    },
    //单选采购订单
    handlePCurrentChange(val) {
      this.addgoodssale.s_id=val.s_id
      this.addgoodssale.s_code=val.s_code
    },
    // 取消选中
    setCurrent(row){
      this.addgoodssale.goodslist=[]
      this.$refs.singleTable.setCurrentRow(row);
    },
    //跳转采购入库
    addPwtergo(){
      this.getPDli(this.addgoodssale.s_id)
      this.addGoodssaleDialogVisible=false

      // this.$router.push({path: "goodssale/addgoodssaleout", query: {editid: this.Goodssaleid}})
    },
    //数量输入框
    isnumberBlur(row){
      if (isNaN(row.sri_number)==true){
        row.sri_number=''
      }else if(row.sri_number<=0){
        row.sri_number=''
      }
      else if(row.sri_number>row.si_number){
        row.sri_number=row.si_number
      }
      this.totalPrice()
    },
    //
    isprice(row){
      if (isNaN(row.si_price)==true){
        row.si_price=row.ga_market_price
      }else if(Number(row.si_price)<=0){
        row.si_price=row.ga_market_price
      }else if(Number(row.si_price)>0){
        console.log("123456789")
        row.si_price=Number(row.si_price).toFixed(2)
      }
      this.totalPrice()
    },
    totalPrice(){
      for (let i=0;i<this.addgoodssale.goodslist.length;i++){
        if (this.addgoodssale.goodslist[i].si_price!=undefined&&this.addgoodssale.goodslist[i].si_number!=undefined){
          // console.log(this.addgoodssale.goodslist[i].si_number)
          let totalprice=this.addgoodssale.goodslist[i].si_price*this.addgoodssale.goodslist[i].si_number
          this.addgoodssale.goodslist[i].si_total_price=totalprice.toFixed(2)
        }else{
          this.addgoodssale.goodslist[i].si_total_price=0
        }
      }
    },
    //销售
    salegoods(){
      this.$refs.addgoodssaleRef.validate(async valid => {
        if (!valid) return
        this.addloading=true
        const {data: res} = await this.$http.post('salereturn/add', this.addgoodssale)
        this.addloading=false
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.$router.go(-1)

      })
    }
  },
}
</script>
<style lang="less" scoped>
.el-col{
  margin-bottom: 20px;
}
.el-cascader{
  width: 300px;
}
.el-select{
  width: 300px;
  margin-right: 30px;
}
.p_remark{
  width: 300px;
  margin-right: 30px;
}
.el-input__inner{
  width: 250px;
}
//.ga_name{
//  width:90%;
//  margin-bottom: 10px;
//  white-space: nowrap;
//  overflow:hidden;
//  text-overflow:ellipsis;
//}
textarea {
  width: 300px;
  height: 100px;
  color: #606266;
  border: 1px solid #DCDFE6;
  background-color: #FFF;
  outline: none;
}
</style>
